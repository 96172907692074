const areasAccordion = document.querySelector('.expertise-section__accordion');

if (areasAccordion) {
  const list = areasAccordion.querySelector('.expertise-section__list');
  const listDesk = areasAccordion.querySelector(
    '.expertise-section__desk-list'
  );

  const itemsArr = list.querySelectorAll('.expertise-section__item');
  const secondItemsArr = listDesk.querySelectorAll(
    '.expertise-section__item-second'
  );

  console.log(itemsArr, secondItemsArr);

  itemsArr.forEach((itemEl, idxEl) => {
    itemEl.addEventListener('click', () => {
      if (!itemEl.classList.contains('active')) {
        for (let i = 0; i < itemsArr.length; i++) {
          itemsArr[i].classList.remove('active');
          secondItemsArr[i].classList.remove('active');
        }
        itemEl.classList.add('active');
        secondItemsArr[idxEl].classList.add('active');

        if (window.innerWidth >= 952) {
          const elementTop = secondItemsArr[idxEl].getBoundingClientRect().top;
          const offsetPosition = window.pageYOffset + elementTop - 120;

          window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth',
          });
        }
      } else {
        if (window.innerWidth < 951) {
          itemEl.classList.remove('active');
          secondItemsArr[idxEl].classList.remove('active');
        }
      }
    });
  });
}

function setBgHeightAreas() {
  if (document.getElementsByClassName('expertise-section__item')[0]) {
    const jobsCardsArr = document.querySelectorAll('.expertise-section__item');

    jobsCardsArr.forEach((card) => {
      const bgEl = card.querySelector('.expertise-section__item-top-bg');
      if (bgEl) {
        const bgElHeight = bgEl?.clientHeight - 5;

        card.style.setProperty('--bg-height', `${bgElHeight}px`);
      }
    });
  }
}

window.addEventListener('load', setBgHeightAreas);
window.addEventListener('resize', setBgHeightAreas);
