if (document.getElementsByClassName('line-decor')[0]) {
  function linesAnimation() {
    const linesDecorArr = document.querySelectorAll('.line-decor');
    linesDecorArr.forEach((titleDecorEl) => {
      const titleDecorPath = titleDecorEl.querySelector('path');
      getPathLength(titleDecorPath);
    });

    function getPathLength(figure) {
      figure.style.strokeDasharray = `${figure.getTotalLength()}`;
      figure.style.strokeDashoffset = `${figure.getTotalLength()}`;

      if (window.innerWidth <= 640) {
        figure.style.strokeWidth = 10;
      }
    }
  }

  linesAnimation();
}
