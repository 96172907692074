import Swiper, { Autoplay, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

let historySwiperWr = document.querySelectorAll('.history-swiper-wr');
historySwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');
    let slidesArr = el.querySelectorAll('.swiper-slide');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
    let wrapper = swiperEl.querySelector('.swiper-wrapper');

    const historyImagesArr =
      el.parentElement.nextElementSibling.querySelectorAll(
        '.history-section__image'
      );

    function updateSliderContainerHeight(swiper) {
      const activeIndex = swiper.realIndex;
      let totalHeight = 0;

      for (let i = 0; i < 1; i++) {
        const slide = slidesArr[activeIndex + i];
        if (slide) {
          totalHeight += slide.offsetHeight;
        }
      }

      if (totalHeight > 0) {
        swiperEl.style.height = `${totalHeight}px`;
      } else {
        setTimeout(() => {
          let delayedTotalHeight = 0;
          for (let i = 0; i < 1; i++) {
            const slide = slidesArr[activeIndex + i];
            if (slide) {
              delayedTotalHeight += slide.offsetHeight;
            }
          }
          swiperEl.style.height = `${delayedTotalHeight}px`;
        }, 100);
      }
    }

    let swiper = new Swiper(swiperEl, {
      modules: [Autoplay, Navigation],
      observer: true, //needs to calculate swiper size
      observeParents: true, //needs to calculate swiper size
      spaceBetween: 0,
      direction: 'vertical',
      slidesPerView: 'auto',
      threshold: 10,
      navigation: {
        nextEl: nextEl,
        prevEl: prevEl,
      },
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: window.innerWidth >= 640,
      },
      on: {
        init: function (swiper) {
          updateSliderContainerHeight(swiper);
          updateActiveImage(swiper);
        },
        slideChange: function (swiper) {
          updateSliderContainerHeight(swiper);
          updateActiveImage(swiper);
        },
      },
    });

    swiper.on('resize', function () {
      updateSliderContainerHeight(swiper);
    });

    function updateActiveImage(swiper) {
      historyImagesArr.forEach((img, index) => {
        if (index === swiper.activeIndex) {
          img.classList.add('active');
        } else {
          img.classList.remove('active');
        }
      });
    }

    swiperObserver(swiper);
  }
});
