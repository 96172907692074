import Swiper, { Autoplay, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';
import { swiperOnResize } from '../utils/swiperOnResize.utils.js';

let teamSwiperWr = document.querySelectorAll('.team-swiper-wr');
teamSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
    let wrapper = swiperEl.querySelector('.swiper-wrapper');

    swiperOnResize('(max-width: 1023px)', swiperEl, {
      modules: [Autoplay, Navigation],
      slidesPerView: 1,
      spaceBetween: 16,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: false,
      },
      breakpoints: {
        500: {
          slidesPerView: 1.5,
        },
        640: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 2.5,
        },
        890: {
          slidesPerView: 3,
        },
      },
      navigation: {
        prevEl: prevEl,
        nextEl: nextEl,
      },
    });
  }
});

function setBottomHeightTeam() {
  if (document.getElementsByClassName('our-team__card')[0]) {
    const jobsCardsArr = document.querySelectorAll('.our-team__card');

    jobsCardsArr.forEach((card) => {
      const bottomEl = card.querySelector('.our-team__card-bottom-subtract');

      const bottomElHeight = bottomEl.clientHeight - 2;

      card.style.setProperty('--bottom-height', `${bottomElHeight}px`);
    });
  }
}

window.addEventListener('load', setBottomHeightTeam);
window.addEventListener('resize', setBottomHeightTeam);
