import Swiper, { Autoplay, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';
import { swiperOnResize } from '../utils/swiperOnResize.utils.js';

let ethosSwiperWr = document.querySelectorAll('.ethos-swiper-wr');
ethosSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
    let wrapper = swiperEl.querySelector('.swiper-wrapper');

    swiperOnResize('(max-width: 768px)', swiperEl, {
      modules: [Autoplay, Navigation],
      slidesPerView: 1,
      spaceBetween: 20,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      breakpoints: {
        500: {
          slidesPerView: 1.5,
        },
        660: {
          slidesPerView: 2,
        },
      },
      navigation: {
        prevEl: prevEl,
        nextEl: nextEl,
      },
    });
  }
});

function setBottomHeightEthos() {
  if (document.getElementsByClassName('ethos-card')[0]) {
    const ethosCardsArr = document.querySelectorAll('.ethos-card');

    ethosCardsArr.forEach((card) => {
      const bgEl = card.querySelector('.ethos-card__bg');
      if (bgEl) {
        const bgElHeight = bgEl?.clientHeight - 10;

        card.style.setProperty('--bg-height', `${bgElHeight}px`);
      }
    });
  }
}

window.addEventListener('load', setBottomHeightEthos);
window.addEventListener('resize', setBottomHeightEthos);
