import './utils/vh.utils.js';
import './utils/animate-on-view.utils';
import './utils/parallax-on-view.utils.js';

import './components/header.component.js';
import './components/uploadFile.component.js';
import './components/inputs.component.js';
import './components/meta-select.component.js';
import './components/animations.component.js';
import './components/jobs-search.component.js';

import './components/marquee.component.js';
import './components/lines.component.js';

/*Sliders*/
import './components/teams.component.js';
import './components/markets.component.js';
import './components/services.component.js';
import './components/case-studies.component.js';
import './components/expertise.component.js';

import './components/swiper-deliver.component.js';
import './components/swiper-global.component.js';
import './components/swiper-team.component.js';

import './components/swiper-areas.component.js';
import './components/swiper-clients-say.component.js';
import './components/swiper-stats.component.js';
import './components/swiper-jobs.component.js';
import './components/swiper-blogs.component.js';
import './components/swiper-hire.component.js';
import './components/swiper-ethos.component.js';
import './components/swiper-talent.component.js';

import './components/swiper-history.component.js';
/*Sliders End*/

import './components/overlayScroll.component.js';

import './libs/countUp.lib.js';

const contentElArticle = document.querySelector(
  '.content-element.article-type'
);
if (contentElArticle) {
  const stylesArr = contentElArticle.querySelectorAll('style');
  stylesArr.forEach((style) => {
    style.remove();
  });
}

window.jsScrollToEl = function jsScrollToEl(targetID) {
  let config = targetID.split('|');
  let duration = +config[1] && +config[1] !== '' ? +config[1] : 800;
  let paddingTop = +config[2] && +config[2] !== '' ? +config[2] : 0;
  let target = config[0];
  let targetElement = document.querySelector(target);

  if (targetElement) {
    let targetOffsetTop = targetElement.offsetTop - paddingTop;
    window.scrollTo({
      top: targetOffsetTop,
      behavior: 'smooth',
    });
    return false;
  }
};

if(document.querySelector('.fixed-btn')) {
  const fixedBtnOpen = document.querySelector(".fixed-btn-open");
  const fixedBtnClose = document.querySelector(".fixed-btn-close");

  if(fixedBtnClose) {
    fixedBtnClose.addEventListener("click", function() {
      if(fixedBtnOpen) {
        fixedBtnOpen.style.opacity = "1";
        fixedBtnOpen.style.pointerEvents = "all";
      }

      fixedBtnClose.style.opacity = "0";
      fixedBtnClose.style.pointerEvents = "none";
      closePopup();
    })
  }
}
